<template>
    <section class="home-education">
      <div class="container section-value-props section-education hidden">
        <div class="section-header">
          <span class="subheading">INVRS Education</span>
          <h2 class="heading-secondary">Say hello to gamified education and goodbye to dry lectures.</h2>
        </div>
        <div class="grid grid--4-cols">
        <div class="value-box hidden">
          <img src="/assets/invrs-education1.png" class="value-icon" />
          <h3 class="heading-tertiary">Earn as you learn.</h3>
          <p class="value-description">
            Earn an in-app currency called Playdough&#8482; and use it to learn more and compete.
          </p>
        </div>
        <div class="value-box hidden">
          <img src="/assets/invrs-education2.png" class="value-icon" />
          <h3 class="heading-tertiary">Learn in a live investment research environment.</h3>
          <p class="value-description">
            Along with being an educational platform, INVRS is used by thousands of investors for research.
          </p>
        </div>
        <div class="value-box hidden">
          <img src="/assets/invrs-education3.png" class="value-icon" />
          <h3 class="heading-tertiary">Support for your learning style.</h3>
          <p class="value-description">
            People learn in different ways so we support multiple learning modalities.
          </p>
        </div>
        <div class="value-box hidden">
          <img src="/assets/invrs-education4.png" class="value-icon" />
          <h3 class="heading-tertiary">Curriculum You Can Trust.</h3>
          <p class="value-description">
            INVRS was founded by a CFA, CPA who graduated with a degree in economics and commerce.
          </p>
        </div>
      </div>
      </div>
    </section>
  </template>
  
  <script>
export default {
  data() {
    return {
      valueProps: [],
      obsOptionsValueProps: {
        root: null,
        threshold: 0.3,
      },
      obsOptionsSection: {
        root: null,
        threshold: 0.15,
      },
      section: "",
    };
  },
  mounted() {
    this.valueProps = document.querySelectorAll(".home-education .value-box");
    this.section = document.querySelector(".home-education .section-value-props");
    const valuePropsObserver = new IntersectionObserver(
      this.revealValueProps,
      this.obsOptionsValueProps
    );
    const sectionObserver = new IntersectionObserver(
      this.revealSection,
      this.obsOptionsSection
    );
    valuePropsObserver.observe(this.section);
    sectionObserver.observe(this.section);
  },
  methods: {
    revealValueProps(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.valueProps.forEach((v, i) => {
            setTimeout(() => v.classList.remove("hidden"), i * 100);
          });
        }
      });
    },
    revealSection(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.section.classList.remove("hidden");
        }
      });
    },
  },
};
</script>
  
<style scoped>
section {
  padding: 9.6rem 0;
  background: linear-gradient(
    180deg,
    #f2f4fa 32.54%,
    rgba(242, 244, 250, 0) 100%
  );
  position: relative;
}

::v-deep(.section-education) {
  max-width: 180rem;
}

.section-value-props {
  transition: all 0.3s ease-in;
}

.value-description {
  font-size: 1.8rem;
  line-height: 2.6rem;
}

.value-icon {
  margin-bottom: 1.6rem;
  width: 8.6rem;
  height: 9.2rem;
}

.section-header {
  display: flex;
  flex-direction: column;
}

.value-box {
  transition: all 0.3s ease-in;
}

.hidden {
  transform: translateY(4.8rem);
  opacity: 0;
}

.earth-icon {
  position: absolute;
  z-index: 99;
  right: 0;
  bottom: 0;
  transform: translate(50%, 50%);
}

/* **************************** */
/* BELOW 1360px (Small desktop/laptop) */
/* **************************** */
@media (max-width: 85em) {
.earth-icon {
    height: 80%;
  }
}

/* **************************** */
/* BELOW 976px (landscape tablets) */
/* **************************** */
@media (max-width: 61em) {
.earth-icon {
    height: 60%;
  }
}

/* **************************** */
/* BELOW 800px (tablet) */
/* **************************** */
@media (max-width: 50em) {
.earth-icon {
    height: 45%;
  }

  p {
    width: 100%;
  }
}

/* **************************** */
/* BELOW 496px (mobile) */
/* **************************** */
@media (max-width: 31em) {
.value-icon {
  margin-bottom: 1.6rem;
  width: 6.4rem;
  height:6.4rem;
}

.earth-icon {
    height: 40%;
  }
}

/* **************************** */
/* BELOW 432px (mobile) */
/* **************************** */
@media (max-width: 27em) {
.earth-icon {
    height: 35%;
  }
}
</style>
