<template>
  <section>
    <div class="container">
      <div class="section-testimonials ">
        <div class="section-header">
          <span class="subheading">Testimonials</span>
          <h2 class="heading-secondary">Don't just take our word for it</h2>
          <p class="heading-text">
            Hear what our users have to say about INVRS.
          </p>
        </div>
        <div class="testimonials-container">
          <div class="testimonial-card">
            <div class="testimonial-card-header">
              <img class="testimonial-img" src="../../assets/ivey.jpg" />
              <div class="testimonial-header-text">
                <h3 class="heading-tertiary testimonial-name">Ivey Investors Society</h3>
                <p class="testimonial-job">@NickPaps27 (Co-President)</p>
              </div>
            </div>
            <p class="testimonial-text">
              I can confidently say that the Ivey Investors Society was incredibly happy with the switch and will be sticking with INVRS for years to come.
              INVRS has truly raised the game in terms of investment analysis and investment competition platforms.
              
            </p>
          </div>
           <div class="testimonial-card">
            <div class="testimonial-card-header">
              <img class="testimonial-img" src="../../assets/odette.png" />
              <div class="testimonial-header-text">
                <h3 class="heading-tertiary testimonial-name">Odette School of Business</h3>
                <p class="testimonial-job">@rachelnapolitano (Co-President)</p>
              </div>
            </div>
            <p class="testimonial-text">
              INVRS is a great platform to use! The individuals who run the platform are extremely helpful and knowledgeable. 
              It has been really useful for organizing stock competitions for the local high schools in our community. 
              It provides students with a great opportunity to learn fundamental skills about stock trading using real time data.
              
            </p>
          </div>
          <div class="testimonial-card">
            <div class="testimonial-card-header">
              <img class="testimonial-img" src="../../assets/nick.jpg" />
              <div class="testimonial-header-text">
                <h3 class="heading-tertiary testimonial-name">Nick Uht</h3>
                <p class="testimonial-job">@NickU4</p>
              </div>
            </div>
            <p class="testimonial-text">
              INVRS has given me the opportunity to connect and collaborate with
              other DIY investors, making my research more effective and
              efficient. If you don't want to pay hundreds of dollars for a
              research website INVRS is the place to go.
            </p>
          </div>
          <div class="testimonial-card">
            <div class="testimonial-card-header">
              <img class="testimonial-img" src="../../assets/diyinvestor.png" />
              <div class="testimonial-header-text">
                <h3 class="heading-tertiary testimonial-name">DIY Value Investor</h3>
                <p class="testimonial-job">@diyvalueinvestor</p>
              </div>
            </div>
            <p class="testimonial-text">
              INVRS is a socially engaging site for people new and experienced
              to investing. Users will find suitable stocks through the INVRS
              stock-screening tool. The stock charts have all key indicators.
              Users are quickly set up after linking their North American
              brokerage account to portfolio analytics.
            </p>
          </div>
          <div class="testimonial-card">
            <div class="testimonial-card-header">
              <img class="testimonial-img" src="../../assets/julie.jpg" />
              <div class="testimonial-header-text">
                <h3 class="heading-tertiary testimonial-name">Julie Dulong</h3>
                <p class="testimonial-job">@JD_Hodl</p>
              </div>
            </div>
            <p class="testimonial-text">
              I love how easy INVRS makes it for me to explore the markets and
              learn from other more experienced investors. With so little time
              to spend searching out news and opportunities, by using INVRS I
              can find it all in one place. That's priceless.
            </p>
          </div>
          <div class="testimonial-card">
            <div class="testimonial-card-header">
              <img class="testimonial-img" src="../../assets/owen.jpg" />
              <div class="testimonial-header-text">
                <h3 class="heading-tertiary testimonial-name">Owen Marshall</h3>
                <p class="testimonial-job">@OMarsh</p>
              </div>
            </div>
            <p class="testimonial-text">
              INVRS is an excellent entry point for the DIY investor looking to
              take things to the next level. It has the data you need, a
              community to bounce ideas off of and collaborate with, and an
              unbeatable price point.
            </p>
          </div>
          <div class="testimonial-card">
            <div class="testimonial-card-header">
              <img class="testimonial-img" src="../../assets/kamil.jpg" />
              <div class="testimonial-header-text">
                <h3 class="heading-tertiary testimonial-name">Kamil Pawlowski</h3>
                <p class="testimonial-job">@kamilpawlowski</p>
              </div>
            </div>
            <p class="testimonial-text">
              As an engineering student I haven't had the chance to learn about
              stocks and investing; thankfully, the community and tools on INVRS
              has helped fill that gap.
            </p>
          </div>
          <div class="testimonial-card">
            <div class="testimonial-card-header">
              <img class="testimonial-img" src="../../assets/ivey.jpg" />
              <div class="testimonial-header-text">
                <h3 class="heading-tertiary testimonial-name">Ivey Investors Society</h3>
                <p class="testimonial-job">@NickPaps27 (Co-President)</p>
              </div>
            </div>
            <p class="testimonial-text">
              I can confidently say that the Ivey Investors Society was incredibly happy with the switch and will be sticking with INVRS for years to come.
              INVRS has truly raised the game in terms of investment analysis and investment competition platforms.
              
            </p>
          </div>
           <div class="testimonial-card">
            <div class="testimonial-card-header">
              <img class="testimonial-img" src="../../assets/odette.png" />
              <div class="testimonial-header-text">
                <h3 class="heading-tertiary testimonial-name">Odette School of Business</h3>
                <p class="testimonial-job">@rachelnapolitano (Co-President)</p>
              </div>
            </div>
            <p class="testimonial-text">
              INVRS is a great platform to use! The individuals who run the platform are extremely helpful and knowledgeable. 
              It has been really useful for organizing stock competitions for the local high schools in our community. 
              It provides students with a great opportunity to learn fundamental skills about stock trading using real time data.
              
            </p>
          </div>
          <div class="testimonial-card">
            <div class="testimonial-card-header">
              <img class="testimonial-img" src="../../assets/nick.jpg" />
              <div class="testimonial-header-text">
                <h3 class="heading-tertiary testimonial-name">Nick Uht</h3>
                <p class="testimonial-job">@NickU4</p>
              </div>
            </div>
            <p class="testimonial-text">
              INVRS has given me the opportunity to connect and collaborate with
              other DIY investors, making my research more effective and
              efficient. If you don't want to pay hundreds of dollars for a
              research website INVRS is the place to go.
            </p>
          </div>
          <div class="testimonial-card">
            <div class="testimonial-card-header">
              <img class="testimonial-img" src="../../assets/diyinvestor.png" />
              <div class="testimonial-header-text">
                <h3 class="heading-tertiary testimonial-name">DIY Value Investor</h3>
                <p class="testimonial-job">@diyvalueinvestor</p>
              </div>
            </div>
            <p class="testimonial-text">
              INVRS is a socially engaging site for people new and experienced
              to investing. Users will find suitable stocks through the INVRS
              stock-screening tool. The stock charts have all key indicators.
              Users are quickly set up after linking their North American
              brokerage account to portfolio analytics.
            </p>
          </div>
          <div class="testimonial-card">
            <div class="testimonial-card-header">
              <img class="testimonial-img" src="../../assets/julie.jpg" />
              <div class="testimonial-header-text">
                <h3 class="heading-tertiary testimonial-name">Julie Dulong</h3>
                <p class="testimonial-job">@JD_Hodl</p>
              </div>
            </div>
            <p class="testimonial-text">
              I love how easy INVRS makes it for me to explore the markets and
              learn from other more experienced investors. With so little time
              to spend searching out news and opportunities, by using INVRS I
              can find it all in one place. That's priceless.
            </p>
          </div>
          <div class="testimonial-card">
            <div class="testimonial-card-header">
              <img class="testimonial-img" src="../../assets/owen.jpg" />
              <div class="testimonial-header-text">
                <h3 class="heading-tertiary testimonial-name">Owen Marshall</h3>
                <p class="testimonial-job">@OMarsh</p>
              </div>
            </div>
            <p class="testimonial-text">
              INVRS is an excellent entry point for the DIY investor looking to
              take things to the next level. It has the data you need, a
              community to bounce ideas off of and collaborate with on research,
              and an unbeatable price point.
            </p>
          </div>
          <div class="testimonial-card">
            <div class="testimonial-card-header">
              <img class="testimonial-img" src="../../assets/kamil.jpg" />
              <div class="testimonial-header-text">
                <h3 class="heading-tertiary testimonial-name">Kamil Pawlowski</h3>
                <p class="testimonial-job">@kamilpawlowski</p>
              </div>
            </div>
            <p class="testimonial-text">
              As an engineering student I haven't had the chance to learn about
              stocks and investing; thankfully, the community and tools on INVRS
              has helped fill that gap.
            </p>
          </div>

          <button class="slider__btn slider__btn--right" @click="nextSlide">
            &rarr;
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      section: "",
      slides: [],
      curSlide: 0,
      obsOptionsPortfolio: {
        root: null,
        threshold: 0.2,
      },
    };
  },

  mounted() {
    this.section = document.querySelector(".section-testimonials");

    this.init();

    const portfolioObserver = new IntersectionObserver(
      this.revealSection,
      this.obsOptionsPortfolio
    );
    portfolioObserver.observe(this.section);
  },

  methods: {
    revealSection(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.section.classList.remove("hidden");
        }
      });
    },

    goToSlide(slide) {
      this.slides.forEach(
        (s, i) => (s.style.transform = `translateX(${(i - slide) * 110}%`)
      );
    },

    nextSlide() {
      const newTestimonialCard = this.slides[this.curSlide].outerHTML;
      const testimonialsContainer = document.querySelector(
        ".testimonials-container"
      );

      testimonialsContainer.insertAdjacentHTML("beforeend", newTestimonialCard);

      this.curSlide++;

      this.init();
    },

    init() {
      this.slides = document.querySelectorAll(".testimonial-card");
      this.goToSlide(this.curSlide);
    },
  },
};
</script>

<style scoped>
.testimonial-name{
  font-size:2.2rem;

}

section {
  padding: 9.6rem 0;
  transform: skewY(3deg);
  background: linear-gradient(
    180deg,
    #f2f4fa 32.54%,
    rgba(242, 244, 250, 0) 100%
  );
}

.container {
  transform: skewy(-3deg);
}

.section-header {
  display: flex;
  flex-direction: column;
}
.heading-text {
  line-height: 3.5rem;
  width: 60%;
  margin-bottom: 4.8rem;
}

.img-container {
  display: flex;
  align-content: center;
  justify-content: center;
}

.img {
  width: 60%;
}

.section-testimonials {
  transition: all 0.3s ease-in;
}

.hidden {
  transform: translateY(4.8rem);
  opacity: 0;
}

.testimonials-container {
  position: relative;
  height: 36rem;
}

.testimonial-card {
  position: absolute;
  background-color: #f2f4fa;
  border-radius: 0.8rem;
  padding: 2.4rem;
  display: flex;
  flex-direction: column;
  width: 40rem;
  box-shadow: 0 0 2.4rem hsl(231deg 22% 25% / 20%);
  transition: all 0.3s ease-in;
}

.testimonial-card-header {
  display: flex;
  align-items: center;
  margin-bottom: 2.4rem;
  width: 100%;
}

.testimonial-header-text {
  display: flex;
  flex-direction: column;
}

.testimonial-name {
  margin-bottom: 0.4rem;
  color: #333;
}

.testimonial-job {
  font-size: 1.8rem;
  color: #4d4d4d;
}

.testimonial-img {
  height: 5rem;
  margin-right: 1.2rem;
  border-radius: 50%;
}

.testimonial-text {
  font-size: 2rem;
  line-height: 3rem;
  color: #4d4d4d;
}

.slider__btn {
  position: absolute;
  top: 50%;
  z-index: 10;
  transition: all 0.2s ease-in;

  border: none;
  background: #693bf5;
  font-family: inherit;
  color: #fff;
  border-radius: 8px;
  height: 4.8rem;
  width: 4.8rem;
  font-size: 2.4rem;
  cursor: pointer;
  box-shadow: 0 0.4rem 3.2rem rgb(105, 59, 245, 0.6);
}

.slider__btn:hover {
  box-shadow: 0 0.4rem 3.2rem rgb(105, 59, 245, 1);
}

.slider__btn--right {
  right: -2%;
  transform: translate(50%, -50%);
}

/* **************************** */
/* BELOW 1360px (Small desktop/laptop) */
/* **************************** */
@media (max-width: 85em) {
  .slider__btn--right {
    right: 2%;
  }
}

/* **************************** */
/* BELOW 800px (tablet) */
/* **************************** */
@media (max-width: 50em) {
  .heading-text {
    width: 100%;
  }
}

/* **************************** */
/* BELOW 432px (mobile) */
/* **************************** */
@media (max-width: 27em) {
  .slider__btn {
    height: 4rem;
    width: 4rem;
  }
}
</style>
