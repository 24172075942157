<template>
    <section class="home-contest">
      <div class="container section-contest section-value-props hidden">
        <div class="section-header">
          <span class="subheading">INVRS Contests & Games</span>
          <h2 class="heading-secondary">Build real world skills in a game environment.</h2>
        </div>
        <div class="grid grid--3-cols">
          <div class="value-box hidden">
            <img src="/assets/invrs-contest1.png" class="value-icon" />
            <h3 class="heading-tertiary">Skill-level based, team & individual contests.</h3>
            <p class="value-description">
              From daily trivia to weeks long team contests, let's play!
            </p>
          </div>
          <div class="value-box hidden">
            <img src="/assets/invrs-contest2.png" class="value-icon" />
            <h3 class="heading-tertiary">Motion to Invest. &#x2122;</h3>
            <p class="value-description">
              Use voteable Motions to decide what your team's portfolio holds. Motions that pass are automatically executed into your Collaborative Portfolio. &#x2122;
            </p>
          </div>
          <div class="value-box hidden">
            <img src="/assets/invrs-contest3.png" class="value-icon" />
            <h3 class="heading-tertiary">Real-world challenges.</h3>
            <p class="value-description">
                Just like the real-world, longer contests may require you to respond to surprises like cash-flows or changing portfolio requirements.
            </p>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    data() {
      return {
        valueProps: [],
        obsOptionsValueProps: {
          root: null,
          threshold: 0.3,
        },
        obsOptionsSection: {
          root: null,
          threshold: 0.15,
        },
        section: "",
      };
    },
    mounted() {
      this.valueProps = document.querySelectorAll(".home-contest .value-box");
      this.section = document.querySelector(".home-contest .section-value-props");
      const valuePropsObserver = new IntersectionObserver(
        this.revealValueProps,
        this.obsOptionsValueProps
      );
      const sectionObserver = new IntersectionObserver(
        this.revealSection,
        this.obsOptionsSection
      );
      valuePropsObserver.observe(this.section);
      sectionObserver.observe(this.section);
    },
    methods: {
      revealValueProps(entries) {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.valueProps.forEach((v, i) => {
              setTimeout(() => v.classList.remove("hidden"), i * 100);
            });
          }
        });
      },
      revealSection(entries) {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.section.classList.remove("hidden");
          }
        });
      },
    },
  };
  </script>
  
<style scoped>
section {
    padding: 9.6rem 0;
    position: relative;
}
.section-contest {
  max-width: 180rem;
  transition: all 0.3s ease-in;
}

.section-value-props {
  transition: all 0.3s ease-in;
}

.value-description {
  font-size: 1.8rem;
  line-height: 2.6rem;
}

.value-icon {
  margin-bottom: 1.6rem;
  width: 9.2rem;
  height: 10rem;
}

.section-header {
  display: flex;
  flex-direction: column;
}

.value-box {
  transition: all 0.3s ease-in;
}

.hidden {
    transform: translateY(4.8rem);
    opacity: 0;
}

/* **************************** */
/* BELOW 496px (mobile) */
/* **************************** */
@media (max-width: 31em) {
    .value-icon {
    margin-bottom: 1.6rem;
    width: 6.4rem;
    height:6.4rem;
    }
}

  
  </style>
  